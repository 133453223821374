import { useFrame, useLoader } from "@react-three/fiber";
import React, { useState, useEffect, forwardRef, useImperativeHandle,useRef } from "react";
import CameraControls from "./Setup/CameraControls";
import { Floor } from "./Setup/Floor";
import Map from "./Landscapes";
import { Heightmap } from "./Landscapes/Heightmap";

const Body = forwardRef((props, ref) => {
  const [colliders, setColliders] = useState(null);
  const childRef = useRef()
  useEffect(() => {
    props.setColliders(colliders)
    console.log(props.isDebug)
  }, [colliders]);



  useImperativeHandle(ref, () => ({

    getColliders() {
      childRef.current.getColliders();
    },
    saveTransform (){
      console.log("ssssssss")
      childRef.current.saveTransform();
    }

  }));

  
  // useImperativeHandle(ref, () => ({

  //   saveTransform() {
  //     console.log("ssssssss")
  //     childRef.current.saveTransform();
  //   }

  // }));
  return (
    <>
      {/* <CameraControls icon={arrow} /> */}
      <Map ref={childRef} isStart={props.isStart} setGroup={props.setGroup} map={props.map} isDebug={props.isDebug} setColliders={setColliders} />
      {/* <Heightmap
        elementSize={0.1525}
        position={[-85.2, -4, 74.5]}
        rotation={[Math.PI / 2, Math.PI, Math.PI]}
      /> */}
      {/* <Floor position={[0, -0.55, 0]} /> */}
    </>
  );
})

export default Body;
