import React from 'react'
import {
    // useHistory, useLocation, useNavigate, 
    Route, Routes} from 'react-router-dom'
import  './authentication.css'
import SignIn from './SignIn/sign-in'
import Register from './Register/register'
const Authentication  = () =>{
    return (
        <div className='auth-main'>
            <Routes>
                <Route path="sign-in" element={<SignIn/>}/>
                <Route path="register" element={<Register/>}/>
            </Routes>
        </div>
    );
}

export default Authentication;