import React, { useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Routes, BrowserRouter, Route } from 'react-router-dom'
import { createStore, compose, applyMiddleware } from 'redux'
import { Provider } from 'react-redux'
import Menu from './menu.js'
import thunk from 'redux-thunk';
import Authentication from './Authentication/index'
import Avatar from "./Avatar/avatar";
import reducer from './store/reducer';
// import { Helmet } from 'react-helmet';

function Main() {
  const store = createStore(reducer,compose(applyMiddleware(thunk)));

  return (
    <Provider store = {store}>
      {/* <Helmet>
        <script></script>
      </Helmet> */}
      
        <BrowserRouter>
          <Routes>
            {/* <Route path='*' element={<Landing />} /> */}
            <Route path='/' element={<Menu />}>
              <Route path='avatar' />
            </Route>
            <Route path='/app' element={<App />} />
            <Route path='/authentication/*' element={<Authentication />}>
              <Route path="sign-in" />
              <Route path="register" />
            </Route>
          </Routes>
        </BrowserRouter>
    </Provider>
  );
}

ReactDOM.render(<Main />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
