import { apiLink } from "../envHandler";
import React, { useState, useEffect, useRef } from 'react'
// import { ClickMode } from 'tsparticles';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { createCookie, getCookie } from '../cookie';
import { addData } from '../firebase';
import { useDispatch } from 'react-redux';
import { toggleLoading } from '../store/actions/action';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
const axios = require('axios')
const Avatar = () => {
    const dispatch = useDispatch();
    const frameStyle = { width: '100vw', height: '100vh' }
    const frame = useRef();
    const file = useRef();
    const navigate = useNavigate()
    const [path, setFile] = useState();
    const subscribe = async (event) => {
        console.log(event)
        const json = parse(event);

        if (json?.source !== 'readyplayerme') {
            return;
        }

        // Susbribe to all events sent from Ready Player Me once frame is ready
        if (json.eventName === 'v1.frame.ready') {
            // console.log(frame.current?.contentWindow.document)
            dispatch(toggleLoading(true))

            frame.current.contentWindow.postMessage(
                JSON.stringify({
                    target: 'readyplayerme',
                    type: 'subscribe',
                    eventName: 'v1.**'
                }),
                '*'
            );
        }

        // Get avatar GLB URL
        if (json.eventName === 'v1.avatar.exported') {
            dispatch(toggleLoading(false))
            const res = await axios.get(json.data.url.replace('glb', 'json'));
            
            // first do regex to get the pure user ID, remove the rest, example URL https://models.readyplayer.me/643dc5aae1b9f547fd31a060.glb
            // remove the link and the .glb which should only output 643dc5aae1b9f547fd31a060
            const picturePreLink = json.data.url.replace('https://models.readyplayer.me/', '').replace('.glb', '');
            console.log("PICTURE PRE LINK", picturePreLink)
            const pictureFinalLink = "https://api.readyplayer.me/v1/avatars/" + picturePreLink + ".png?cacheControl=true&scene=fullbody-portrait-v1&blendShapes[Wolf3D_Head][mouthSmile]=0.8&blendShapes[Wolf3D_Head][browInnerUp]=0.2";

            const gender = res.data.outfitGender;
            setFile(json.data.url);
            const fileName = uuidv4() + ".glb";
            sendFile(json.data.url, fileName, gender).then(res => {
                console.log(res)
                if (res.statusCode === 201) {
                    createCookie("modelPath", fileName, 7);
                    createCookie("modelPureID", picturePreLink, 7);
                    // check if there's already a modelPicture cookie present with a value, if not, create one
                    if (!getCookie('modelPicture')) {
                        createCookie("modelPicture", pictureFinalLink, 7);
                    }
                    createCookie("gender", gender, 7);
                    navigate('/');
                }
            })
        }

        // Get user id
        if (json.eventName === 'v1.user.set') {
            console.log(`User with id ${json.data.id} set: ${JSON.stringify(json)}`);
        }
    }
    
    // get ip, but time out after 4.3 seconds if no response
    // const res = await axios.get('https://geolocation-db.com/json/')

    const getIP = async () => {
        return new Promise((resolve, reject) => {
            const timeout = setTimeout(() => {
                reject(new Error('Request timed out'));
            }, 9300);
            axios.get('https://geolocation-db.com/json/').then(res => {
                clearTimeout(timeout);
                resolve(res.data.IPv4);
            }).catch(err => {
                clearTimeout(timeout);
                reject(err);
            })
        })
    }

    const sendFile = async (path, fileName, gender) => {
        const user = getCookie('user');
        const modelPathData = getCookie('modelPath');
        const modelPureIDData = getCookie('modelPureID');
        const modelPictureData = getCookie('modelPicture');
        // const modelPathData = getCookie('modelPath');
        const today = moment().toString();
        const data = {
            email: user,
            ipAddress: await getIP(),
            date: today,
            file: fileName,
            gender: gender,
            modelPath: modelPathData,
            modelPureID: modelPureIDData,
            modelPicture: modelPictureData
        }
        addData('models', user, data, true)
        try {
            const data = { url: path, file: fileName }
            const resp = await axios.post(apiLink + "/uploadFile", data);
            return resp.data.response;
        } catch (err) {
            console.error(err);
        }
    };
    const parse = (event) => {
        try {
            return JSON.parse(event.data);
        } catch (error) {
            return null;
        }
    }

    useEffect(() => {
        window.addEventListener('message', subscribe);
        document.addEventListener('message', subscribe);
    }, [])
    return (

        <div style={{ overflow: 'scroll' }}>
            <a ref={file} href={path}></a>
            <iframe ref={frame} style={frameStyle} class="frame" src="https://xrplay.readyplayer.me/avatar?frameApi" allow="camera *; microphone *"></iframe>
        </div>
    );
}


export default Avatar;
