import React, { 
    useState, 
    // useEffect 
} from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../firebase'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { signInWithPopup, GoogleAuthProvider,TwitterAuthProvider } from 'firebase/auth';
import { FcGoogle } from 'react-icons/fc'
import { BsTwitter } from 'react-icons/bs'
import './register.css'
// import { propTypes } from 'react-bootstrap/esm/Image';
import { createCookie } from '../../cookie';
const Register = (props) => {
    const [pwd, setPwd] = useState()
    const [email, setEmail] = useState()
    const [confirmpwd, setConfirm] = useState()

    const navigate = useNavigate();

    const validatePassword = () => {
        let isValid = true
        if (pwd !== '' && confirmpwd !== '') {
            if (pwd !== confirmpwd) {
                isValid = false
                console.log('Confirm pwd does not match.')
                // props.onError("Sorry, the passwords you've entered do not match!")
            }
        }
        return isValid
    }


    const loginGoogle = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider )
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                // ...
                console.log("Twitter Result: ", result)
                console.log("COOKIE USER", result.user.email)
                createCookie('user', result.user.email, 7);
                
                console.log("COOKIE PHOTO", result.user.photoURL)
                createCookie('photoURL', result.user.photoURL, 7)

                console.log("COOKIE DISPLAY NAME", result.user.displayName)
                createCookie('displayName', result.user.displayName, 7)

                props.closeRegister(false)
                props.onSuccess('Successfully logged in as '+ result.user.email);
                navigate('/avatar')
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
                console.log(error.message)
                props.onError(error.message)

            });
    }

    const loginTwitter = () => {
        const provider = new TwitterAuthProvider();
        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = TwitterAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                console.log(result.user)
                // ...
                console.log("Twitter Result: ", result)
                console.log("COOKIE USER", result.user.email)
                createCookie('user', result.user.email, 7);
                
                console.log("COOKIE PHOTO", result.user.photoURL)
                createCookie('photoURL', result.user.photoURL, 7)

                console.log("COOKIE DISPLAY NAME", result.user.displayName)
                createCookie('displayName', result.user.displayName, 7)
                props.closeLogin(false)
                props.onSuccess('Successfully logged in as ' + result.user.email);
                navigate('/avatar')
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.email;
                // The AuthCredential type that was used.
                const credential = TwitterAuthProvider.credentialFromError(error);
                // ...
                // console.log("WARNING - Twitter Error CODE", errorCode)
                // console.log("WARNING - User's Twitter Email", email)
                // console.log("WARNING - User's Twitter Credential", credential)
                // console.log("WARNING - Twitter Error Message", errorMessage)
                props.onError(error.message)
            });
    }
    const register = () => {
        if(!email || !pwd || !confirmpwd){
            props.onWarning("Please enter all the fields required.")
            return;
        }

        if(pwd !== confirmpwd){
            props.onWarning("Sorry, Your passwords don't match.")
            return;
        }

        if (validatePassword()) {
            // Create a new user with email and password using firebase
            createUserWithEmailAndPassword(auth, email, pwd)
                .then((res) => {
                    console.log(res.user)
                    props.onSuccess('Successfully registered.')
                    navigate('../sign-in');
                })
                .catch(err => props.onError(err.message))
        }
    }

    const redirectLogin = () =>{
        props.goLogin()
    }

    return (
        <div className='sign-in'>
            <div className='register-modal'>
                <div className='sign-header'>
                </div>
                <div className='sign-body'>
                    <p style={{ color: 'white', fontSize: '36px', margin: '0px' }}>Register</p>
                    <div>
                        <p style={{ color: 'white', marginBottom: '5px' }}>Email</p>
                        <input
                            type='email'
                            placeholder='username@gmail.com'
                            style={{
                                background: 'transparent',
                                marginBottom: '10px',
                                height: '50px',
                                borderRadius: '10px',
                                backgroundColor: '#23283D',
                                border: '1px solid grey',
                                color: 'white',
                                width: '100%',
                                outline: 'none',
                                padding: '10px'
                            }}
                            onChange={(e) => { setEmail(e.target.value) }}
                        />
                    </div>
                    <div>
                        <p style={{ color: 'white', marginBottom: '5px' }}>Password</p>
                        <input
                            type='password'
                            placeholder='Password'
                            style={{
                                background: 'transparent',
                                marginBottom: '10px',
                                height: '50px',
                                borderRadius: '10px',
                                backgroundColor: '#23283D',
                                border: '1px solid grey',
                                color: 'white',
                                width: '100%',
                                outline: 'none',
                                padding: '10px'
                            }}
                            onChange={(e) => { setPwd(e.target.value) }}
                        />
                    </div>
                    <div>
                        <p style={{ color: 'white', marginBottom: '5px' }}>Confirm Password</p>
                        <input
                            type='password'
                            placeholder='Confirm'
                            style={{
                                background: 'transparent',
                                marginBottom: '10px',
                                height: '50px',
                                borderRadius: '10px',
                                backgroundColor: '#23283D',
                                border: '1px solid grey',
                                color: 'white',
                                width: '100%',
                                outline: 'none',
                                padding: '10px'
                            }}
                            onChange={(e) => { setConfirm(e.target.value) }}
                        />
                    </div>
                    <div style={{ margin: '30px 0px' }}>
                        <button onClick={()=>{register(); console.log('dd')}} style={{ width: '100%',cursor:'pointer', height: '50px', borderRadius: '10px', border: 'none', color: 'white', backgroundColor: '#003465' }}>Register</button>
                    </div>
                    <p style={{ width: '100%', color: 'white', textAlign: 'center', fontSize: '14px' }}>Or continue with</p>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <button onClick={() => { loginGoogle() }} style={{ width: '120px',cursor:'pointer', fontSize: '24px', paddingTop: '10px', height: '50px', margin: '5px', backgroundColor: 'white', border: 'none', borderRadius: '10px' }} ><FcGoogle /></button>
                        <button  onClick={()=>{loginTwitter()}} style={{ width: '120px', fontSize: '24px', paddingTop: '10px',cursor:'pointer', color: '#1c9ceb', height: '50px', margin: '5px', backgroundColor: 'white', border: 'none', borderRadius: '10px' }} ><BsTwitter /></button>
                    </div>
                    <p style={{ textAlign: 'center', fontSize: '14px', marginTop: '30px', color: 'white', width: '100%' }}>Already have an account?  <strong className='span-register' onClick={()=>{redirectLogin()}} style={{ fontSize: '600' }}> Login</strong></p>
                    <p style={{ textAlign: 'center', fontSize: '12px', marginTop: '30px', color: 'white', width: '100%' }}>By proceeding you agree to our <a href="https://0xm0.com/pp/" > Privacy Policy</a> and <a href="https://0xm0.com/tos/" > Terms of Service</a> </p>
                
                </div>
                <div className='sign-footer'></div>
            </div>
        </div>
    );
}

export default Register;