// import env
import path from "path";
import dotenv from "dotenv";

dotenv.config({ path: path.resolve(__dirname, ".env") });

var linkSelected = "";

if (process.env.NODE_ENV === "production") {

    linkSelected = "https://api.dare.me";

} else if (process.env.NODE_ENV === "development") {

    linkSelected = "https://api.dare.me";
}

export const apiLink = linkSelected;