// src.firebase.js
import { initializeApp } from "firebase/app"
import { getFirestore, collection, getDocs, serverTimestamp, query, where, doc, setDoc } from "firebase/firestore";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth"


const firebaseConfig = {
  apiKey: "AIzaSyD3Sf_OL_9mlEkjkfPKnNeLVpyW-A9Hmtk",
  authDomain: "threejs-game-0xm0.firebaseapp.com",
  projectId: "threejs-game-0xm0",
  storageBucket: "threejs-game-0xm0.appspot.com",
  messagingSenderId: "734283034170",
  appId: "1:734283034170:web:62d89da40883ee87bb4f03",
  measurementId: "G-21REV1QJ73"
};

// Initialize Firebase and Firebase Authentication
const app = initializeApp(firebaseConfig)
const db = getFirestore(app);
const auth = getAuth(app)
signInWithPopup(auth)
  .then((result) => {
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    // The signed-in user info.
    const user = result.user;
    // ...
  }).catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    // console.log(error)
    // console.log("WARNING - Google Auth Error CODE", errorCode)
    // console.log("WARNING - User's Google Auth Email", email)
    // console.log("WARNING - User's Google Auth Credential", credential)
    // console.log("WARNING - Google Auth Error Message", errorMessage)
    // ...
  });
const addData = async (col, docu, data, time) => {
  if (time)
    data.timestamp = serverTimestamp();
  await setDoc(doc(db, col, docu), data);

}

const getData = async (col, doc, con, dat) => {
  try {
    const q = query(collection(db, col), where(doc, con, dat));
    const querySnapshot = await getDocs(q);
    console.log({ col, doc, con, dat })
    const value = []
    querySnapshot.forEach((doc) => {
      console.log(doc.data())
      value.push(doc.data())
      // doc.data() is never undefined for query doc snapshots
    });
    if (value.length === 0)
      return false;
    else
      return value;
  } catch (error) {
    console.log('error', error)
    return false;
  }

}


export { auth, signInWithPopup, addData, getData }
