import React, { useRef, useState, useEffect, useContext } from "react";
import { useGLTF, useFBX } from "@react-three/drei";
import { Html } from "@react-three/drei";

import * as THREE from "three";
import { MeshBVH, MeshBVHVisualizer } from "three-mesh-bvh";
// import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { OutlinePass } from "three/examples/jsm/postprocessing/OutlinePass";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader";
// import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { BufferGeometryUtils } from "three/examples/jsm/utils/BufferGeometryUtils";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import {
  CSS3DObject,
  CSS3DRenderer,
} from "three/examples/jsm/renderers/CSS3DRenderer";
import { useLoader, useThree, useFrame } from "@react-three/fiber";
import loadedContext from "./index";
import {
  computeBoundsTree,
  disposeBoundsTree,
  acceleratedRaycast,
} from "three-mesh-bvh";
import { useStore } from "../store";
import { ColorifyShader } from "three-stdlib";

function Box(props) {
  return (
    <mesh>
      <boxGeometry args={[props.x, props.y, props.z]} />
      <meshStandardMaterial color="hotpink" />
    </mesh>
  );
}

var Element = function (path, x, y, z, ry) {
  var div = document.createElement("div");
  div.style.width = "480px";
  div.style.height = "360px";
  div.style.backgroundColor = "#000";
  var iframe = document.createElement("iframe");
  iframe.style.width = "480px";
  iframe.style.height = "360px";
  iframe.style.border = "0px";
  iframe.src = [path].join("");
  div.appendChild(iframe);
  var object = new CSS3DObject(div);
  object.position.set(x, y, z);
  object.rotation.y = ry;
  return object;
};

const Importer = ({ onHover, ...props }) => {
  const occlude = useRef();
  const boxRef = useRef();
  const movieRef = useRef();
  const ref = useRef(null);
  const [active, setActive] = useState(false);
  const [objects, setObjects] = useState([]);
  const [object, setObject] = useState([]);
  const [element, setElement] = useState();
  const [type, setType] = useState();
  let meshes = [];
  let environment = new THREE.Group();
  const { scene, camera, gl, size } = useThree();
  const composer = new EffectComposer(gl);
  const renderPass = new RenderPass(scene, camera);
  composer.addPass(renderPass);
  const outlinePass = new OutlinePass(
    new THREE.Vector2(size.width, size.height),
    scene,
    camera
  );
  composer.addPass(outlinePass);
  const effectFXAA = new ShaderPass(FXAAShader);
  effectFXAA.uniforms["resolution"].value.set(
    1 / size.width,
    1 / size.innerHeight
  );
  composer.addPass(effectFXAA);
  useEffect(() => {
    console.log("loading");
    let type = props.path.slice(-3);
    if (props.name.includes("Youtube")) {
      setType("movie");
      type = "movie";
      props.setLoading(Math.random());
    } else setType(props.path.slice(-3));

    if (type == "glb") {
      const list = [];
      const loader = new GLTFLoader();
      loader.load(props.path, (res) => {
        console.log(res);
        const gltfScene = res.scene;
        gltfScene.scale.setScalar(1);

        const box = new THREE.Box3();
        box.setFromObject(gltfScene);
        box.getCenter(gltfScene.position).negate();
        gltfScene.updateMatrixWorld(true);

        const toMerge = {};
        gltfScene.traverse((c) => {
          if (c.isMesh) {
            const hex = c.material.color.getHex();
            toMerge[hex] = toMerge[hex] || [];
            toMerge[hex].push(c);
            meshes.push(c);
          }
        });

        environment = new THREE.Group();
        const list = [];
        meshes.forEach((mesh) => {
          const geom = mesh.geometry.clone();
          geom.applyMatrix4(mesh.matrixWorld);
          const newMesh = new THREE.Mesh(geom, mesh.material);
          list.push(newMesh);
        });
        setObjects([...list]);
        console.log("name", Math.random());
        props.setLoading(Math.random());
      });
    } else if (type == "movie") {
      var element = new Element(props.path, 0, 0, 0, 0);
      setElement(element);
      console.log(element);
      if (movieRef.current) {
        movieRef.current.add(element);
        console.log(movieRef.current);
      }
    } else {
      const loader = new FBXLoader();
      const list = [];
      loader.load(props.path, (res) => {
        res.position.set(0, 0, 0);
        console.log(res);
        const box = new THREE.Box3();
        box.setFromObject(res);
        box.getCenter(res.position).negate();
        res.updateMatrixWorld(true);
        res.traverse((c) => {
          if (c.isMesh) {
            console.log(c);
            list.push(c);
          }
        });
        // boxRef.current.add(res)
        setObject(res);
      });
    }
  }, [props.name]);

  return (
    <>
      {type == "fbx" && (
        <primitive
          object={object}
          ref={boxRef}
          {...props}
          position={[props.position.x, props.position.y, props.position.z]}
          rotation={[props.rotation.x, props.rotation.y, props.rotation.z]}
          scale={[props.scale.x, props.scale.y, props.scale.z]}
          onClick={(e) => {
            e.stopPropagation();
            console.log(boxRef.current);
            props.setObject(e.object.parent);
            ref.current = e.object;
            onHover(ref);
            setActive(!active);
          }}
          name={props.name}
        />
      )}
      {type == "glb" && (
        <group
          ref={boxRef}
          {...props}
          position={[props.position.x, props.position.y, props.position.z]}
          rotation={[props.rotation.x, props.rotation.y, props.rotation.z]}
          scale={[props.scale.x, props.scale.y, props.scale.z]}
          onClick={(e) => {
            e.stopPropagation();
            console.log(boxRef.current);
            props.setObject(e.object.parent);
            ref.current = e.object;
            onHover(ref);
            setActive(!active);
          }}
          name={props.name}
        >
          {objects?.map((index) => (
            <mesh
              key={index.uuid}
              geometry={index.geometry}
              material={index.material}
            ></mesh>
          ))}
        </group>
      )}
      {type == "movie" && (
        <group
          position={[props.position.x, props.position.y, props.position.z]}
          rotation={[props.rotation.x, props.rotation.y, props.rotation.z]}
          scale={[props.scale.x, props.scale.y, props.scale.z]}
          name={props.name}
          path={props.path}
        >
          <Html transform position={[0, 0, 0]}>
            <iframe
              width="800px"
              style={{ pointerEvents: "none" }}
              height="500px"
              src={
                props.path +
                "?autoplay=1&mute=0&loop=1&color=white&controls=1&modestbranding=1&playsinline=0&rel=0&enablejsapi=1&vq=hd1080&watch?v=7hdgx-W2VMQ&list=PLScC8g4bqD461pCfNojDx0fVxjGl7Og_4"
              }
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
       
            />
          </Html>
        </group>
      )}
    </>
  );
};

export default Importer;
