/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from "three";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useGLTF, useFBX, useAnimations } from "@react-three/drei";
import { RoundedBoxGeometry } from "three/examples/jsm/geometries/RoundedBoxGeometry.js";
import {
  computeBoundsTree,
  disposeBoundsTree,
  acceleratedRaycast,
} from "three-mesh-bvh";
import { useFrame, useGraph, useThree } from "@react-three/fiber";
import { Quaternion, Vector3 } from "three";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { useStore } from "../store";
import { SkeletonUtils } from "three/examples/jsm/utils/SkeletonUtils";
import { getCookie } from "../cookie";
const keys = {
  KeyW: "forward",
  KeyS: "backward",
  KeyA: "left",
  KeyD: "right",
  Space: "jump",
  ShiftLeft: "run",
};
const moveFieldByKey = (key) => keys[key];

// temporary data
const walkDirection = new Vector3();
const rotateAngle = new Vector3(0, 1, 0);
const rotateQuarternion = new Quaternion();
const cameraTarget = new Vector3();
let player, controls;
let playerVelocity = new THREE.Vector3();
let jumpVelocity = new THREE.Vector3();
let upVector = new THREE.Vector3(0, 1, 0);
let tempVector = new THREE.Vector3();
let playerIsOnGround = false;
let playerIsJumping = false;
let jumpingStarted = false;
let jumping = false;
let tempVector2 = new THREE.Vector3();
let tempBox = new THREE.Box3();
let tempMat = new THREE.Matrix4();
let tempSegment = new THREE.Line3();

const Soldier = forwardRef((props, ref) => {
  const model = useRef();
  const cam = useRef();
  const character = useRef();
  const skin = useRef();
  const gltfLoader = new GLTFLoader()

  THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
  THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
  THREE.Mesh.prototype.raycast = acceleratedRaycast;
  const gender = getCookie('gender');

  const maleAnims = {
    idle: 'anims/Idle.glb',
    walk: 'anims/Walk.glb',
    run: 'anims/Run.glb',
    jump: 'anims/Jump.glb',
  }

  const femaleAnims = {
    idle: 'anims/IdleW.glb',
    walk: 'anims/WalkW.glb',
    run: 'anims/RunW.glb',
    jump: 'anims/JumpW.glb',
  }

  const [animations, setAnimations] = useState(new Array())
  const [mixer] = useState(() => new THREE.AnimationMixer());
  const [Body, setBody] = useState(() => new THREE.SkinnedMesh());
  const changeSync = useStore((state) => state.changeSync);
  const colliders = useStore((state) => state.colliders);
  let clip,
    init = true,
    skeleton;
  let actions = [];
  const [index, setIndex] = useState("Idle");
  const [name, setName] = useState("Idle");
  const [isFirst, setFirst] = useState(false);
  const [toggleRun, setToggleRun] = useState(false);
  const runVelocity = useStore((state) => state.runVelocity);
  const { scene, camera, state } = useThree();
  const position = useStore((state) => state.position);
  const target = useStore((state) => state.target);
  let isSync = false;
  let pos = new THREE.Vector3();
  function switchRunToggle() {
    setToggleRun(!toggleRun);
  }

  const getAnimationPath = () => {
    const gender = getCookie('gender');
    if (gender == 'masculine')
      return maleAnims;
    else
      return femaleAnims;
  }

  const getModelPath = () => {
    const path = "https://pz-axnu3fr5gmdfh9pz.b-cdn.net/wk6qhlwv8k84tqqsjt/models/player/" + getCookie('modelPath');

    return path;
  }

  useImperativeHandle(ref, () => ({
    teleport() {
      isSync = true;
    },
    toggleView(mode) {
      setFirst(mode);
    },
  }));

  useEffect(() => {
    console.log(scene);
    fpsHandler(0);
  }, [scene]);

  // useEffect(() => {
  //   if (props.model == "Soldier") setBody(nodes.nodes.vanguard_Mesh);
  //   else setBody(nodes.nodes.Mesh001);
  // }, [props.model]);

  useEffect(() => {
    if (model.current) {
      if (props.map === "Spawn") {
        model.current.position.y = 20;
        model.current.position.x = 0;
        model.current.position.z = 0;
      }
      else if (props.map === "Cinema") {
        model.current.position.y = 20;
        model.current.position.x = 0;
        model.current.position.z = 0;
      }
      else if (props.map === "City") {
        model.current.position.x = 0;
        model.current.position.y = 20;
        model.current.position.z = 0;
      }
      else if (props.map === "Forest") {
        model.current.position.y = -10;
      }
      else if (props.map === "Desert") {
        model.current.position.y = 10;
      }
      else if (props.map === "Beach") {
        model.current.position.y = -10;
      }
      else if (props.map === "Snow") {
        model.current.position.y = 10;
      }
      else if (props.map === "Volcano") {
        model.current.position.y = -10;
      }
      else if (props.map === "MooMooLand") {
        model.current.position.y = 10;
      }
      else if (props.map === "Space") {
        model.current.position.y = -10;
      }
    }
  }, [props.map]);

  const usePlayerControls = () => {
    const [movement, setMovement] = useState({
      forward: false,
      backward: false,
      left: false,
      right: false,
      jump: false,
      run: false,
    });
    let running = false;
    useEffect(() => {
      const handleKeyDown = (e) => {
        props.setStart(true);
        if (e.code == "Space") {
          if (playerIsOnGround) {
            // playerVelocity.y = 50;
          }

          // setTimeout(() => {
          setMovement((m) => {
            return { ...m, [moveFieldByKey(e.code)]: true };
          });
          // }, 500);

        }
        setMovement((m) => {
          return { ...m, [moveFieldByKey(e.code)]: true };
        });
      };
      const handleKeyUp = (e) => {
        if (e.code == "ShiftLeft") running = false;
        setMovement((m) => {
          return { ...m, [moveFieldByKey(e.code)]: false };
        });
        setToggleRun(false);
      };

      const handleMouseDown = () => {
        props.setStart(true);
      };
      document.addEventListener("keydown", handleKeyDown);
      document.addEventListener("keyup", handleKeyUp);
      document.addEventListener("mousedown", handleMouseDown);
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
        document.removeEventListener("keyup", handleKeyUp);
        document.addEventListener("mousedown", handleMouseDown);
      };
    }, []);
    return movement;
  };

  function fpsHandler(fps) {
    let Delta = 0.024;
    if (fps > 24) {
      Delta = Delta * Math.random(60 * 0.025) * fps;
    }
    else if (fps > 60) {
      Delta = Delta * Math.random(60 * 0.025) * fps;
    }
  }

  useEffect(() => {
    const animPaths = getAnimationPath();
    const modelPath = getModelPath();
    console.log(animPaths)
    gltfLoader.load(
      modelPath,
      (gltf) => {
        console.log(gltf)
        character?.current.add(gltf.scene)
        gltfLoader.load(
          animPaths.idle,
          (gltf) => {
            const clip = mixer.clipAction(gltf.animations[0], character.current).play()
            setAnimations(animations => [...animations, clip])
            gltfLoader.load(
              animPaths.walk,
              (gltf) => {
                const clip = mixer.clipAction(gltf.animations[0], character.current)
                setAnimations(animations => [...animations, clip])
                gltfLoader.load(
                  animPaths.run,
                  (gltf) => {
                    const clip = mixer.clipAction(gltf.animations[0], character.current)
                    setAnimations(animations => [...animations, clip])
                    gltfLoader.load(
                      animPaths.jump,
                      (gltf) => {
                        const clip = mixer.clipAction(gltf.animations[0], character.current)
                        setAnimations(animations => [...animations, clip])
                      }
                    )
                  }
                )
              }
            )
          }
        )
      }
    )


    const material = new THREE.MeshStandardMaterial();
    material.opacity = 0.0;
    material.transparent = true;
    material.depthWrite = false;
    player = new THREE.Mesh(
      new RoundedBoxGeometry(1.0, 2.0, 1.0, 10, 0.5),
      material
    );
    player.geometry.translate(0, 1.2, 0);
    player.capsuleInfo = {
      radius: 0.5,
      segment: new THREE.Line3(
        new THREE.Vector3(),
        new THREE.Vector3(0, -0.1, 0.0)
      ),
    };
    player.castShadow = true;
    player.receiveShadow = true;
    player.material.shadowSide = 2;
    model.current.add(player);
  }, []);
  const { forward, backward, left, right, jump, run } = usePlayerControls();
  useLayoutEffect(() => {
    console.log({ name })
    if (name === 'Idle') {
      if (index === "Walk") {
        animations[1]?.fadeOut(0.5);
        animations[2]?.fadeOut(0);
      }
      else if (index === "Jump") {
        animations[3].fadeOut(1);
      }
      else {
        animations[1]?.fadeOut(0);
        animations[2]?.fadeOut(0.5);
      }
      animations[0]?.reset().fadeIn(0.5).play()
    }
    else if (name === 'Walk') {
      animations[3]?.reset().fadeOut(0);
      if (index === "Idle") {
        animations[0]?.fadeOut(0.5);
        animations[2]?.fadeOut(0);
      }
      else {
        animations[0]?.fadeOut(0);
        animations[2]?.fadeOut(0.5);
      }
      animations[1]?.reset().fadeIn(0.5).play()
    }
    else if (name === 'Run') {
      animations[3].reset().fadeOut(0);

      if (index === "Idle") {
        animations[0]?.fadeOut(0.5);
        animations[1]?.fadeOut(0);
      }
      else {
        animations[0]?.fadeOut(0);
        animations[1]?.fadeOut(0.5);
      }
      animations[2]?.reset().fadeIn(0.5).play()
    }
    else if (name === 'Jump' && !jumping) {
      animations[0]?.fadeOut(0);
      animations[1]?.fadeOut(0);
      animations[2]?.fadeOut(0);

      const action = animations[3];
      action.reset();
      // action.clampWhenFinished = true;
      // action.timeScale = 1;
      // action.setLoop(THREE.LoopOnce, 1);
      action.play();
      playerIsJumping = true;
      jumping = true;
      jumpingStarted = false;
      setTimeout(() => {
        jumpingStarted = true;
        playerIsJumping = false;
        playerVelocity.y = 50;

        jumpVelocity.y = 60;
      }, 500);

      // setTimeout(() => {
      //   jumpingStarted = false;
      // }, 1300);
    }
    setIndex(name);
  }, [name, props.model, toggleRun]);

  function updatePlayer(delta) {
    let velocity = runVelocity;
    delta = Math.abs(delta)
    if (run) velocity *= 2.5;
    if (playerVelocity.y > -600)
      playerVelocity.y += playerIsOnGround ? 0 : -30 * delta * 5;
    model.current.position.addScaledVector(playerVelocity, delta * 0.15);
    if (jumpingStarted) {
      jumpVelocity.y += -30 * delta * 5;
      player.position.addScaledVector(jumpVelocity, delta * 0.15);
      if (player.position.y < 0) {
        player.position.y = 0;
        jumpingStarted = false;
        jumping = false;
      }
    }
    let angleYCameraDirection = Math.atan2(
      camera.position.x - cam.current.getWorldPosition(pos).x,
      camera.position.z - cam.current.getWorldPosition(pos).z
    );
    let directionOffset = getDirectionOffset(forward, backward, right, left);
    if (isFirst) {
      rotateQuarternion.setFromAxisAngle(
        rotateAngle,
        angleYCameraDirection
      );
    }
    else {
      rotateQuarternion.setFromAxisAngle(
        rotateAngle,
        angleYCameraDirection + directionOffset
      );
    }
    if (forward || backward || left || right) {
      model.current.quaternion.rotateTowards(rotateQuarternion, 0.7);
    }
    camera.getWorldDirection(walkDirection);
    walkDirection.y = 0;
    walkDirection.normalize();
    walkDirection.applyAxisAngle(rotateAngle, directionOffset);

    if (forward) {
      if (!playerIsJumping) {
        tempVector.set(0, 0, -1).applyAxisAngle(upVector, angleYCameraDirection);
        model.current.position.addScaledVector(tempVector, velocity * 0.7 * delta);
      }
    }

    if (backward) {
      tempVector.set(0, 0, 1).applyAxisAngle(upVector, angleYCameraDirection);
      model.current.position.addScaledVector(tempVector, velocity * 0.7 * delta);
    }

    if (left) {
      tempVector.set(-1, 0, 0).applyAxisAngle(upVector, angleYCameraDirection);
      model.current.position.addScaledVector(tempVector, velocity * 0.7 * delta);
    }

    if (right) {
      tempVector.set(1, 0, 0).applyAxisAngle(upVector, angleYCameraDirection);
      model.current.position.addScaledVector(tempVector, velocity * 0.7 * delta);
    }
    // }
    model.current.updateMatrixWorld();

    // // adjust player position based on collisions
    const capsuleInfo = player.capsuleInfo;
    tempVector.set(0, 1, 0)
    // player.position.addScaledVector(tempVector , 0.01)
    // console.log({capsuleInfo})
    tempBox.makeEmpty();
    tempMat.copy(colliders.matrixWorld).invert();
    tempSegment.copy(capsuleInfo.segment);

    // get the position of the capsule in the local space of the collider
    tempSegment.start
      .applyMatrix4(model.current.matrixWorld)
      .applyMatrix4(tempMat);
    tempSegment.end
      .applyMatrix4(model.current.matrixWorld)
      .applyMatrix4(tempMat);

    // // get the axis aligned bounding box of the capsule
    tempBox.expandByPoint(tempSegment.start);
    tempBox.expandByPoint(tempSegment.end);

    tempBox.min.addScalar(-capsuleInfo.radius);
    tempBox.max.addScalar(capsuleInfo.radius);

    colliders.geometry.boundsTree.shapecast(null, {
      intersectsBounds: (box) => box.intersectsBox(tempBox),

      intersectsTriangle: (tri) => {
        // check if the triangle is intersecting the capsule and adjust the
        // capsule position if it is.
        const triPoint = tempVector;
        const capsulePoint = tempVector2;

        const distance = tri.closestPointToSegment(
          tempSegment,
          triPoint,
          capsulePoint
        );
        if (distance < capsuleInfo.radius) {
          const depth = capsuleInfo.radius - distance;
          const direction = capsulePoint.sub(triPoint).normalize();

          tempSegment.start.addScaledVector(direction, depth);
          tempSegment.end.addScaledVector(direction, depth);
        }
      },
    });

    // get the adjusted position of the capsule collider in world space after checking
    // triangle collisions and moving it. capsuleInfo.segment.start is assumed to be
    // the origin of the player model.
    const newPosition = tempVector;
    newPosition.copy(tempSegment.start).applyMatrix4(colliders.matrixWorld);

    // check how much the collider was moved
    const deltaVector = tempVector2;
    deltaVector.subVectors(newPosition, model.current.position);

    // if the player was primarily adjusted vertically we assume it's on something we should consider ground
    playerIsOnGround = Math.abs(deltaVector.y);
    // deltaVector.y > Math.abs(delta * playerVelocity.y * 0.15);
    const offset = Math.max(0.0, deltaVector.length() - 1e-5);
    deltaVector.normalize().multiplyScalar(offset);
    // console.log(playerVelocity.y)
    // adjust the player model
    model.current.position.add(deltaVector);

    if (!playerIsOnGround) {
      deltaVector.normalize();
      playerVelocity.addScaledVector(
        deltaVector,
        -deltaVector.dot(playerVelocity)
      );
    } else {
      playerVelocity.set(0, 0, 0);
    }
  }
  useFrame((state, delta) => {
    if (init) {
      init = false;
    }
    if (jump) {
      setName('Jump')
    }
    else if (!forward && !backward && !left && !right && !jumping) {
      setName("Idle");
    } else if ((forward || backward || left || right) && !jumping) {
      if (run) {
        setName("Run");
      } else {
        setName("Walk");
      }
    }

    // console.log({delta})
    mixer.update(delta);
    if (props.isBuild == false) {
      if (props.camera.current.getDistance() < 2) {
        props.setMin(0);
        props.setMax(0.4);
      } else if (props.camera.current.getDistance() > 0.5 && isFirst) {
        props.setMin(2);
        props.setMax(5);
      }

      cam.current.getWorldPosition(pos);
      camera.position.sub(state.controls.target);
      state.controls.target.copy(cam.current.getWorldPosition(pos));
      camera.position.add(cam.current.getWorldPosition(pos));
      // camera.rotation.set(model.current.rotation)
    }
    if (isSync) {
      if (target) {
        console.log(target);
        console.log(camera);
        const parent = target.parent;
        target.position.set(
          camera.position.x,
          camera.position.y,
          camera.position.z
        );
        target.rotation.set(
          camera.rotation.x,
          camera.rotation.y,
          camera.rotation.z
        );
      }
      isSync = false;
      console.log(camera.position);
    }
    if (colliders) updatePlayer(delta);
  });
  return (
    <>
      <group
        position={[0, 10, 0]}
        castShadow
        ref={model}
        {...props}
        dispose={null}
      >
        <group
          position={[0, -0.68, 0]}
          rotation={[0, Math.PI, 0]}
        >
          <group
            ref={character}
          >
            <mesh ref={cam} scale={0.001} position={[0, 1.5, 0.8]}>
              <boxGeometry attach="geometry" args={[1, 1, 1]} />
              <meshPhongMaterial
                attach="material"
                opacity={0.00}
                transparent={true}
                color="hotpink"
              />
            </mesh>
          </group>
        </group>
      </group>
    </>
  );
});

export default Soldier;

function getDirectionOffset(w, s, d, a) {
  let directionOffset = 0; // w
  if (s) {
    if (a) {
      directionOffset = Math.PI / 4 + Math.PI / 2; // s+a
    } else if (d) {
      directionOffset = -Math.PI / 4 - Math.PI / 2; // s+d
    } else {
      directionOffset = Math.PI; // s
    }
  } else if (d) {
    directionOffset = -Math.PI / 2; // d
    if (d && w) {
      directionOffset = -Math.PI / 4; // w+a
    }
  } else if (a) {
    directionOffset = Math.PI / 2; // w+a
    if (a && w) {
      directionOffset = Math.PI / 4; // w+a
    }
  }

  return directionOffset;
}


