/*
Original script is at: https://github.com/pmndrs/gltfjsx
*/

import { apiLink } from "../envHandler";

import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useGLTF, useFBX } from "@react-three/drei";
import * as THREE from "three";
import { MeshBVH, MeshBVHVisualizer } from "three-mesh-bvh";
// import * as BufferGeometryUtils from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { BufferGeometryUtils } from "three/examples/jsm/utils/BufferGeometryUtils.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { useLoader, useFrame, useThree } from "@react-three/fiber";
import { EffectComposer, Outline } from "@react-three/postprocessing";

import {
  computeBoundsTree,
  disposeBoundsTree,
  acceleratedRaycast,
} from "three-mesh-bvh";
import { useStore } from "../store";
import Importer from "./importer.js";
import { ColorifyShader } from "three-stdlib";
const axios = require("axios").default;

// const newPost = {
//   userId: 1,
//   title: 'A new post',
//   body: 'This is the body of the new post'
// };

const Map = forwardRef((props, ref) => {
  const group = useRef();
  const meshes = useRef();
  const city = useRef();
  const forest = useRef();
  const objects = useRef();
  const [objectList, setObjectList] = useState(null);
  const [selected, setting] = useState(null);
  const collision = useRef();
  // let modelPath;

  // if (props.map == "Spawn")
  //   // modelPath = "https://pz-axnu3fr5gmdfh9pz.b-cdn.net/wk6qhlwv8k84tqqsjt/models/Spawn.glb";
  //   modelPath = "https://d1a370nemizbjq.cloudfront.net/c3203e40-3027-45ea-a150-b51aeba64f12.glb";
  // else modelPath = "https://pz-axnu3fr5gmdfh9pz.b-cdn.net/wk6qhlwv8k84tqqsjt/models/Scene2.glb";
  
  // const { nodes, materials } = useGLTF(modelPath);
  // const scene1 = useFBX("models/low-poly-scene-forest-waterfall/source/Low_poly_scene.fbx")
  const { scene } = useThree();

  var collider;
  var visualizer,
    environment = new THREE.Group();
  THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
  THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
  THREE.Mesh.prototype.raycast = acceleratedRaycast;
  const [init, setInit] = useState(false);
  const [isDebug, setDebug] = useState(useStore((state) => state.isDebug));
  const position = useStore((state) => state.position);
  const rotation = useStore((state) => state.rotation);
  const isBuild = useStore((state) => state.build);
  // const object = useLoader(
  //   GLTFLoader,
  //   "https://pz-axnu3fr5gmdfh9pz.b-cdn.net/wk6qhlwv8k84tqqsjt/models/others/test1.glb"
  // );
  const changePosition = useStore((state) => state.changePosition);
  const changeRotation = useStore((state) => state.changeRotation);
  const changeScale = useStore((state) => state.changeScale);
  const changeTarget = useStore((state) => state.changeTarget);
  const colliders = useStore((state) => state.colliders);
  const changeColliders = useStore((state) => state.changeColliders);
  const [hovered, onHover] = useState(null);
  const isSelected = hovered ? [hovered] : undefined;
  const [isLoading, setLoading] = useState(0);
  const [isLoaded, setLoaded] = useState(-1);
  const sendSaveRequest = (data) => {
    try {
      // const resp = axios.post("http://localhost:7000/saveData", data);
      const resp = axios.post(apiLink + "/saveData", data);
      console.log("Sorry, You don't have permission to modify this world.");
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };

  const sendSceneRequest = async (scene) => {
    try {
      const data = { body: scene };
      const resp = await axios.post(apiLink + "/getScenes", data);
      console.log(resp.data.Scenes);
      sendPostRequest(resp.data.Scenes[0] + ".json");
      return resp.data;
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };
  const saveState = (object) => {
    console.log(object);
    if (object !== null) {
      const data = {
        Objects: [],
        scene: "",
      };
      data.scene = props.map;
      for (let index = 0; index < object.current.children.length; index++) {
        const element = object.current.children[index];
        const object1 = {
          name: element.name,
          file: element.path,
          position: {
            x: element.position.x,
            y: element.position.y,
            z: element.position.z,
          },
          rotation: {
            x: element.rotation.x,
            y: element.rotation.y,
            z: element.rotation.z,
          },
          scale: { x: element.scale.x, y: element.scale.y, z: element.scale.z },
        };
        data.Objects.push(object1);
      }
      console.log(JSON.stringify(data));
      sendSaveRequest(data);
    }
  };

  const sendPostRequest = async (scene) => {
    try {
      const data = { body: scene };
      const resp = await axios.post(apiLink + "/getData", data);
      const list = [];

      setObjectList([...resp.data.Objects]);
      return resp.data;
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };

  useEffect(() => {
    const map = props.map + ".json";
    setLoaded(0);

    if (props.map !== undefined) sendPostRequest(map);
  }, [props.map]);

  useEffect(() => {
    sendSceneRequest();
  }, []);

  useEffect(() => {
    setLoaded(isLoaded + 1);
    console.log("name1", isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (objectList !== null) {
      changeColliders(null);
      if (isLoaded == objectList.length && props.isStart) {
        setTimeout(() => {
          if (objects.current.children.length !== 0) {
            props.setColliders(null);
            ref.current.getColliders();
          }
        }, 1000);
      }
    }
  }, [isLoaded, props.isStart]);

  useImperativeHandle(ref, () => ({
    getColliders() {
      const list = [];
      const toMerge = {};
      const mergeList = [];
      var environment = new THREE.Group();

      objectList.forEach((index) => {
        if (index.name != "Youtube")
          list.push(scene.getObjectByName(index.name));
      });
      for (let i = 0; i < list.length; i++) {
        if (list[i]) {
          const meshes = [];
          const geometries = [];
          list[i].traverse((c) => {
            if (c.isMesh) {
              meshes.push(c);
            }
          });
          meshes.forEach((mesh) => {
            const cloned = mesh.geometry.clone();
            cloned.applyMatrix4(mesh.matrixWorld);
            for (const key in cloned.attributes)
              if (key !== "position") cloned.deleteAttribute(key);
            geometries.push(cloned);
          });
          const mergedGeometry = BufferGeometryUtils.mergeBufferGeometries(
            geometries,
            false
          );
          console.log(mergedGeometry);
          mergeList.push(mergedGeometry);
          mergedGeometry.boundsTree = new MeshBVH(mergedGeometry, {
            lazyGeneration: true,
          });
        }
      }
      const geometries = [];
      mergeList.forEach((mesh) => {
        mesh.morphAttributes = new Object();
        console.log(mesh.morphAttributes);
        geometries.push(mesh);
      });
      const mergedGeometry = BufferGeometryUtils.mergeBufferGeometries(
        geometries,
        false
      );
      mergedGeometry.boundsTree = new MeshBVH(mergedGeometry, {
        lazyGeneration: false,
      });

      collider = new THREE.Mesh(mergedGeometry);
      collider.material.wireframe = true;
      collider.material.opacity = 0.5;
      collider.material.transparent = true;
      collision.current.children = new Array();
      if (isBuild) collision.current.add(collider);
      console.log(mergedGeometry);
      console.log(collider);
      changeColliders(collider);
      props.setColliders(collider);
    },
    saveTransform() {
      saveState(objects);
      alert("Sorry, You don't have permission to modify this world");
    },
  }));

  useEffect(() => {
    sendPostRequest("Spawn.json");
  }, []);

  useEffect(() => {
    console.log(selected);
    if (selected !== null) {
      changeTarget(selected);
      changePosition(
        selected.position.x,
        selected.position.y,
        selected.position.z
      );
      changeRotation(
        selected.rotation.x,
        selected.rotation.y,
        selected.rotation.z
      );
      changeScale(selected.scale.x, selected.scale.y, selected.scale.z);
    }
  }, [selected]);

  useEffect(() => {
    // ref.current.getColliders();
  }, [props.isDebug, props.map]);

  useEffect(() => {
    console.log({ objectList });
    return () => {};
  }, [objectList]);
  return (
    <group scale={1} rotation={[0, 0, 0]} ref={group} {...props} dispose={null}>
      <group ref={collision}></group>
      <group ref={objects} position={[0, 0, 0]} scale={1}>
        {objectList?.map((index) => {
          return (
            <Importer
              key={index.name}
              isLoading={isLoading}
              setLoading={setLoading}
              onHover={onHover}
              position={index.position}
              rotation={index.rotation}
              scale={index.scale}
              setObject={setting}
              name={index.name}
              path={index.file}
            ></Importer>
          );
        })}
      </group>

      {isBuild && (
        <EffectComposer multisampling={4} autoClear={false}>
          <Outline
            selection={isSelected}
            visibleEdgeColor="#F35415"
            edgeStrength={3}
            edgeThickness={1000}
          />
        </EffectComposer>
      )}
    </group>
  );
});

export default Map;

// useGLTF.preload("https://d1a370nemizbjq.cloudfront.net/c3203e40-3027-45ea-a150-b51aeba64f12.glb");
