import { apiLink } from "./envHandler";

import create from "zustand";
import { devtools } from "zustand/middleware";
const axios = require("axios").default;
let data;
const sendPostRequest = async (scene) => {
  try {
    const resp = await axios.post(apiLink + "/getScenes", data);
    // listScene = resp.data.Scenes;
    data = resp.data.Scenes;
  } catch (err) {
    // Handle Error Here
    console.error(err);
  }
};
sendPostRequest();

export const useStore = create(
  devtools((set) => ({
    debug: false,
    build: false,
    sync: false,
    import: false,
    character: "Soldier",
    map: "Spawn",
    color: "#ffffff",
    runVelocity: 5,
    position: { x: 0, y: 0, z: 0 },
    rotation: { x: 0, y: 0, z: 0 },
    scale: { x: 0, y: 0, z: 0 },
    target: null,
    object: null,
    colliders: null,
    scenes: data,
    saved: false,
    changeDebug: (isDebug, color, runVelocity, build) =>
      set((state) => ({ debug: isDebug, build: build, color, runVelocity })),
    changeCharacter: (character) => set((state) => ({ character: character })),
    changePosition: (x, y, z) => set((state) => ({ position: { x, y, z } })),
    changeRotation: (x, y, z) => set((state) => ({ rotation: { x, y, z } })),
    changeScale: (x, y, z) => set((state) => ({ scale: { x, y, z } })),
    changeMap: (map) => set((state) => ({ map: map })),
    changeImport: (value) => set((state) => ({ import: value })),
    changeSync: (value) => set((state) => ({ sync: value })),
    changeTarget: (value) => set((state) => ({ target: value })),
    changeObject: (value) => set((state) => ({ object: value })),
    changeSave: (value) => set((state) => ({ saved: value })),
    changeColliders: (value) => set((state) => ({ colliders: value })),
    changeScenes: (value) => set((state) => ({ scenes: value })),
  }))
);
