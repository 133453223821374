import React, { useState, useEffect, Suspense } from "react";
import ReactDOM from "react-dom";
import { Button, Text, Modal, useModal, Row, Input, Checkbox } from "@nextui-org/react";
import { Avatar as ProfilePic } from "@nextui-org/react";
import confetti from "canvas-confetti";
import { useNavigate, Routes, Route } from 'react-router-dom'
import SignIn from './Authentication/SignIn/sign-in'
import Register from './Authentication/Register/register'
import "./index.css";
import { addData } from "./firebase";
import App from "./App";
import greenlet from "greenlet";
import SpeedTester from "browser-speed-test";
import { getCookie } from "./cookie";
import ProgressBar from 'react-bootstrap/ProgressBar';
import { ToastContainer, toast } from 'react-toastify';
import Avatar from "./Avatar/avatar";
import { toggleLoading } from "./store/actions/action";
import { useDispatch, useSelector } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
// fonts
// const testEngine = new SpeedTester({
//   url: "https://pz-axnu3fr5gmdfh9pz.b-cdn.net/wk6qhlwv8k84tqqsjt/models/Cinema/TreeScreened.glb",
//   fileSize: 2320
// });

// const Poster =
//   "https://pz-axnu3fr5gmdfh9pz.b-cdn.net/wk6qhlwv8k84tqqsjt/vid-textures-landing/Poster.png";
// const landingPlanet =
//   "https://sketchfab.com/models/894ad84ceb8b444a91fbc05f20530bcd";

// import Utils from './Setup/Utils';
// import toast, { Toaster } from 'react-hot-toast'

// const joinWorld = () => toast.success("You have joined the world, Spawn");
// import { useFrame } from '@react-three/fiber'
// import Landing from "./Landscapes/Landing.js";

const NewLine = () => {
  return <br />;
}

const handleConfetti = () => {
  confetti({
    zIndex: 999,
    particleCount: 100,
    spread: 70,
    origin: { x: 0.75, y: 0.8 },
  });
};
// wait for the window to load & user interaction

function Menu() {

  const navigate = useNavigate()
  // Modal handler
  const dispatch = useDispatch();
  const { bindings } = useModal();

  // States
  const [ready, set] = useState(false);
  const [visible, setVisible] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showLearnMore, setShowLearnMore] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [video, setVideo] = useState(false);
  // const [data] = cityLocation();
  const [speed, setSpeed] = React.useState(0);
  // make a timeout for 5 seconds to run testEngine

  useEffect(() => {
    if (!getCookie('user')) {
      setShowLogin(true)
    }
    else {
      setTimeout(() => {
        handler();
      }, 200);
    }
  }, [])

  const handlePlanetStart = () => {
    // after the user clicks the button, we need to select the iframe with the id "planet" and then send a mouse scroll event to it
    // this will trigger the planet to start spinning
    const planet = document.getElementById("planet");
    planet.contentWindow.postMessage(
      {
        type: "mousewheel",
        event: {
          deltaX: 10,
        },
      },
      "*"
    );
  };


  // Open/Close Modal
  const handler = () => setVisible(true);
  const handlerTwo = () => {
    setShowLearnMore(true)
    console.log("Learn More was clicked")
  };
  const settingsHandler = () => {
    setShowSettings(true)
    console.log("Settings was clicked")
  };

  // const settingsHandler = () => setVisible(true);
  // const settingsCloseHandler = () => {
  //   setVisible(false);
  //   console.log("closed settings modal");
  // };

  const closeHandler = () => {
    setVisible(false);
    console.log("closed initial modal");
  };
  const closeHandlerTwo = () => {
    setShowLearnMore(false);
    console.log("closed handler2 modal");
  };

  const closeSettings = () => {
    setShowSettings(false);
    console.log("Settings modal closed");
  };

  const videoHandler = () => {
    setVideo(false);
    console.log("closed bg video");
  };

  const closeHandlerLogin = () => {
    setShowLogin(false);
  }

  const closeHandlerRegister = () => {
    setShowRegister(false);
  }

  const goRegister = () => {
    setShowLogin(false)
    setShowRegister(true);
  }

  const goLogin = () => {
    setShowLogin(true)
    setShowRegister(false);
  }

  const joinWorld = () => {
    const path = getCookie('modelPath')
    console.log(path)
    if (!path) {
      toast_warning('Currently you have no model saved. Please configure model.');
      return;
    }
    else {
      navigate('/app')
    }
  }

  // listen if user presses ESC button on keyboard, if they do, set(false)
  const state = useSelector(state => state.isStateLoading)
  console.log('ready?', state)
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        set(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
    document.getElementById('doneButton').addEventListener('click',done,false)

  }, []);

  const done = () =>{
    console.log('done')
  }


  const toast_error = (message) => {
    console.log(message)
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }

  const toast_success = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }

  const toast_warning = (message) => {
    toast.warning(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }

  const toast_info = (message) => {
    toast.info(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  }
  // check cookies for photoURL, if it exists, use it, otherwise use modelPicture from cookies, if both aren't available use default
  const pictureFinalLink = getCookie('photoURL') ? getCookie('photoURL') : getCookie('modelPicture') ? getCookie('modelPicture') : 'https://cdn.discordapp.com/avatars/135274896606363648/9901c86491738a9d755321146986bb0f.webp?size=512'
  console.log("profie pic",pictureFinalLink)
  const displayName = getCookie('displayName') ? getCookie('displayName') : 'Guest'

  // 




  // check if the link is from readyplayer.me, if it is, log the link, otherwise log google.com/test.png
  // if (pictureFinalLink.includes('readyplayer.me')) {
  //   console.log("RPM Picture Link: ", pictureFinalLink) // https://api.readyplayer.me/v1/avatars/643dc9e884ef6d6b93d81e07.png?cacheControl=true&scene=fullbody-portrait-v1&blendShapes[Wolf3D_Head][mouthSmile]=0.8&blendShapes[Wolf3D_Head][browInnerUp]=0.2
  //   let avatarProfilePicture = pictureFinalLink + "&scene=fullbody-portrait-v1&blendShapes[Wolf3D_Head][mouthSmile]=0.8&blendShapes[Wolf3D_Head][browInnerUp]=0.2";
  //   const finalPicture = avatarProfilePicture
  //  // or if the link is from google, log the link 
  // } else if (pictureFinalLink.includes('google')) {  
  //   console.log("External Provider Picture Link: ", pictureFinalLink) //  https://lh3.googleusercontent.com/a-/AOh14GhVtJWnkvHGBl5NoFevxr8MOI7KbgD8FC7NB1ek=s96-c
  //   let avatarProfilePicture = pictureFinalLink;
  //   const finalPicture = avatarProfilePicture
  // } else {
  //   console.log("No Picture Link.. Using default picture") https://cdn.discordapp.com/avatars/135274896606363648/9901c86491738a9d755321146986bb0f.webp?size=512
  //   let avatarProfilePicture = '"';
  //   const finalPicture = avatarProfilePicture 
  // }

  // console.log("Avatar Picture: ", finalPicture)
  
  // check cookies for displayName, if it exists, name in the cookie, otherwise use "Guest"
  


  return (
    <>
    {/* Google had a stroke trying to load pictures and threw a 403, I added the meta to avoid 403 */}
      <meta name="referrer" content="no-referrer" /> 


      <div className="dot" />
      {/* Top left corner of the screen add <Text> */}
      <div className="top-left">

      </div>
      <div>

        <div
          className={`fullscreen bg ${ready ? 'ready' : 'notready'} ${ready && 'clicked'}`}
        >
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='colored'
          />
          <div className="stuck" style={{ filter: (showLogin || showRegister) && 'blur(10px)' }}>

            {/* add a style for max width in landingPlanet */}
            <div className="landingPlanet"
            >
              <iframe title="" id="planet" frameBorder="0" allowFullScreen mozallowfullscreen="true" webkitallowfullscreen="true" allow="autoplay; fullscreen; xr-spatial-tracking" xr-spatial-tracking="true" execution-while-out-of-viewport="true" execution-while-not-rendered="true" web-share="true" src="https://sketchfab.com/models/894ad84ceb8b444a91fbc05f20530bcd/embed?autospin=0.1&autostart=1&annotations_visible=0&ui_animations=0&ui_infos=0&ui_stop=0&ui_inspector=0&ui_watermark_link=0&ui_watermark=0&ui_hint=0&ui_ar=0&ui_help=0&ui_settings=0&ui_vr=0&ui_fullscreen=0&ui_annotations=0&ui_theme=dark&dnt=1"
                style={{
                  width: '100%',
                  height: '100%',
                  position: 'fixed',
                  top: '0',
                  left: '0',
                  zIndex: state ? '-1' : '0',
                  border: 'none',
                  // disable interaction
                  touchAction: 'none',
                  WebkitTouchAction: 'none',
                  msTouchAction: 'none',
                  userSelect: 'none',
                  WebkitUserSelect: 'none',
                  msUserSelect: 'none',
                  MozUserSelect: 'none',
                  KhtmlUserSelect: 'none',
                  OUserSelect: 'none',
                  cursor: 'default',
                  pointerEvents: 'none',
                  overflow: 'hidden',
                  // END disable interaction
                }}
              > </iframe>
              <Routes>
                <Route path="settings" />
                <Route path="avatar" element={<Avatar />} />
              </Routes>
            </div>

            <Suspense fallback={null}>{/* <Landing /> */}</Suspense>

            
            









            {/* INITIAL MODAL SHOWED - */}
            <Modal
              closeButton
              blur
              aria-labelledby="modal-title-1"
              // open={visible}
              // onClose={closeHandler}
            >
              <Modal.Header>
                <Text
                  id="modal-title"
                  b
                  size={38}
                  css={{
                    textGradient: "45deg, $blue400 -20%, $pink600 100%",
                  }}
                >
                  Hey there!
                </Text>
              </Modal.Header>
              <Modal.Body>
                <Row justify="space-between">
                  <Text size={14}>

                    <NewLine />
                    Make sure you have enough{" "}
                    <Text color="warning" b size={15}>
                      Bandwidth
                    </Text>{" "}
                    to play!
                    <NewLine />
                    If this is your first time {"&"} you think
                    you're stuck; It's most likely that your internet connection
                    is{" "}
                    <Text color="primary" b size={15}>
                      slow
                    </Text>
                  </Text>
                </Row>
              </Modal.Body>
              <Modal.Body>
                <Row justify="space-between">
                  <Text
                    css={{
                      textGradient: "45deg, $purple500 -20%, $pink500 100%",
                    }}
                    size={17}
                  >
                    Expected Size:{" "}
                    <Text b size={15}>
                      248 MB
                    </Text>
                  </Text>
                </Row>
              </Modal.Body>
              <Modal.Body>
                <Row justify="space-between">
                  <Text
                    css={{
                      textGradient: "45deg, $purple500 -20%, $pink500 100%",
                    }}
                    size={17}
                  >
                    Expected load time per world:{" "}
                    <Text b size={15}>
                      20 seconds
                    </Text>{" "}
                    <Text>(average 50mbps link speed)</Text>
                  </Text>
                </Row>
              </Modal.Body>
              <Modal.Body>
                <Row justify="space-between">
                  <Text
                    css={{
                      textGradient: "45deg, $purple500 -20%, $pink500 100%",
                    }}
                    size={17}
                  >
                    Connected to Server:{" "}
                    <Text b size={15}>
                      Melbourne
                    </Text>{" "}
                  </Text>
                </Row>
              </Modal.Body>
              <Modal.Body>
                <Row justify="space-between">
                  <Text size={14}>
                    But don't worry! I've attached Edge locations to this server,
                    So wherever you are, the closest server to you will be sending
                    you game data. This way you download the game files much{" "}
                    <Text b size={14}>
                      Faster!
                    </Text>
                    . Next time you play some of the data will be saved allowing
                    for much faster{" "}
                    <Text b size={15}>
                      loading times
                    </Text>{" "}
                    due to caching.
                  </Text>
                </Row>
              </Modal.Body>
              <Modal.Body>
                <Row justify="space-between">
                  <Text size={14}>
                    If you are moving around worlds, for example from{" "}
                    <Text b size={14}>
                      City
                    </Text>{" "}
                    to{" "}
                    <Text b size={14}>
                      Spawn
                    </Text>
                    , You'll start falling endlessly. To fix this simply{" "}
                    <Text b size={15}>
                      Reload the page
                    </Text>
                  </Text>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  auto
                  flat
                  color="warning"
                  onClick={() => window.history.back()}
                >
                  Go back
                </Button>
                <Button auto color="success" onClick={closeHandler}>
                  Let's go!
                </Button>
              </Modal.Footer>
            </Modal>

            {!state && <div className="intro-message">

            
            <Button
                  auto
                  rounded
                  ripple={false}
                  size="xl"
                  // onclick handleConfetti but also set(true)
                  onClick={() => joinWorld() && handleConfetti()}
                  // onClick={() => handlePlanetStart()}
                  css={{
                    // make the button 10% away from bottom of screen
                    position: "fixed",
                    bottom: "15%",
                    // center the button
                    left: "45%",
                    background: "$gray",
                    fontWeight: "$semibold",
                    boxShadow: "$md",
                    overflow: "visible",
                    color: "$white",
                    px: "$18",
                    "&:after": {
                      content: '""',
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      background: "$black",
                      opacity: 0.85,
                      borderRadius: "$pill",
                      border: "1px solid $white",
                      transition: "all 0.4s ease",
                    },
                    "&:hover": {
                      transform: "",
                      "&:after": {
                        transform: "scaleX(1.3) scaleY(1.2)",
                        opacity: 0.5,
                      },
                    },
                    "&:active": {
                      transform: "translateY(-2px)",
                    },
                  }}
                >
                  Start
                </Button>

              {/* <h1><span>Hey ${Player} </span></h1>
            <h4><span>Ready to Play?</span></h4> */}
              <div style={{ width: '100%' }}>
                {/* <ProgressBar striped variant="success" now={speed} /> */}
                {/* <p style={{textAlign:'center'}}>Download speed:{speed}kb/s</p> */}
              </div>

              {/* <NewLine> */}

              {/* <a onClick={() => set(true)}>Click (needs fullscreen)</a> */}
              
              {/* make a state to check if they have logged in, you can use getCookie function, if they have a cookie, show the <button> tag */}
              
              
              <div>
                {/* Edit Avatar - TEMPORARY BUTTON */}
                <Button
                  auto
                  rounded
                  ripple={false}
                  size="xl"
                  // onclick handleConfetti but also set(true)
                  onClick={() => {
                    console.log("toggleLoading");
                    navigate('/avatar');
                  }}
                  css={{
                    // make it in the bottom right corner of the page
                    position: "fixed",
                    bottom: "0",
                    right: "0",
                    background: "$gray50",
                    fontWeight: "$medium",
                    boxShadow: "$md",
                    overflow: "visible",
                    color: "$white",
                    px: "$18",
                    "&:after": {
                      content: '""',
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      background: "$black",
                      opacity: 0.85,
                      // borderRadius: "$pill",
                      // border: "1px solid $white",
                      transition: "all 0.4s ease",
                    },
                    "&:hover": {
                      transform: "",
                      "&:after": {
                        // transform: "scaleX(1.3) scaleY(1.2)",
                        // opacity: 0.5,
                      },
                    },
                    "&:active": {
                      // transform: "translateY(-2px)",
                    },
                  }}
                >
                  Edit Your Avatar
                </Button>

                {/* My Account - OPENS MODAL */}

                <Button
                  auto
                  rounded
                  ripple={false}
                  size="xl"
                  // onclick use  handlerTwo but also console log
                  onClick={() => {
                    console.log("toggleLoading");
                    settingsHandler();
                  }}
                  css={{
                    // make it in the bottom right corner of the page
                    position: "fixed",
                    top: "2%",
                    right: "3%",
                    background: "$gray50",
                    fontWeight: "$medium",
                    boxShadow: "$md",
                    overflow: "visible",
                    color: "$gray200",
                    px: "$18",
                    "&:after": {
                      content: '""',
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      background: "$black",
                      opacity: 0.85,
                      // borderRadius: "$pill",
                      // border: "1px solid $white",
                      transition: "all 0.4s ease",
                    },
                    "&:hover": {
                      transform: "",
                      "&:after": {
                        // transform: "scaleX(1.3) scaleY(1.2)",
                        // opacity: 0.5,
                      },
                    },
                    "&:active": {
                      // transform: "translateY(-2px)",
                    },
                  }}
                >
                  My Account
                </Button>
                
                <ProfilePic
                  src={pictureFinalLink}
                  referrerPolicy="no-referrer"
                  color="primary"
                  size="xl"
                  onClick={() => {
                    console.log("toggleLoading");
                    settingsHandler();
                  }}
                  css={{ 
                    position: "fixed",
                    top: "2%",
                    right: "2%",
                  }}
                />
                
                {/* <Button auto shadow color="secondary" onClick={handlerTwo}>
                  Learn more!
                </Button> */}
            
            <h1>
              <Text
              h1
              size={60}
              css={{
                position: "fixed",
                top: "5%",
                left: "3%",
                fontWeight: "$medium",
                fontFamily: "IBMBold",
                color: "$white"
              }}
            >
              Hey <span>{displayName}</span>
            </Text>
            <Text
              h6
              size={60}
              css={{
                position: "fixed",
                top: "10%",
                left: "3%",
                fontWeight: "$medium",
                fontFamily: "IBMBold",
                textGradient: "45deg, $yellow600 -20%, $red600 100%",
              }}
            >
              <span>Ready to Join?</span>
            </Text>
              </h1>


              </div>
              {/* LEARN MORE MODAL */}

              <Modal
                scroll
                width="600px"
                open={showLearnMore}
                onClose={closeHandlerTwo}
              >
                <Modal.Header>
                  <Text id="modal-title" b size={18}>
                    0xm0 ThreeJS Portfolio
                  </Text>
                </Modal.Header>
                <Modal.Body>
                  <Text id="modal-description">
                    This is a simple portfolio site built with ThreeJS and
                    React, I decided to make this site because I wanted to learn
                    ThreeJS more as I found it super intriguing. After searching
                    a lot about it I realized that I could use it to literally
                    make any game I'd like with ease.
                    <NewLine />
                  </Text>
                </Modal.Body>
                <Modal.Footer>
                  <Button auto onClick={closeHandlerTwo}>
                    Nice!
                  </Button>
                </Modal.Footer>
              </Modal>
              {/* SIGN IN MODAL */}

              <Modal
                preventClose
                aria-labelledby="modal-title-2"
                open={showLogin}
                onClose={closeHandlerLogin}
                style={{ background: 'none' }}
              >
                <Modal.Body>
                  <SignIn
                    onError={toast_error}
                    onSuccess={toast_success}
                    onInfo={toast_info}
                    onWarning={toast_warning}
                    goRegister={goRegister}
                    closeLogin={setShowLogin}
                  />
                </Modal.Body>
              </Modal>
              {/* REGISTER MODAL */}

              <Modal
                preventClose
                aria-labelledby="modal-title-3"
                open={showRegister}
                onClose={closeHandlerRegister}
                style={{ background: 'none' }}
              >
                <Modal.Body>
                  <Register
                    goLogin={goLogin}
                    closeRegister={setShowRegister}
                    onError={toast_error}
                    onSuccess={toast_success}
                    onInfo={toast_info}
                    onWarning={toast_warning}
                  />
                </Modal.Body>
              </Modal>
              {/* SETTINGS MODAL */}

              <Modal
              closeButton
              blur
              autoMargin
              width="80%"
              aria-labelledby="settings-modal"
              open={showSettings}
              onClose={closeSettings}
              css={{
                background: "#16181a",
                color: "$gray200",
                // center the modal


              }}
            >
              <Modal.Header>
                <Text 
                id="settings-modal" 
                size={35}
                color="white"
                style={{
                  marginLeft: "-85%",
                  marginTop: "-1%",
                  fontFamily: "IBMBold"
                }}
                >
                  Settings
                </Text>
              </Modal.Header>
              <Modal.Body>
                
                
                
              </Modal.Body>
              <Modal.Footer>
                <Button auto color="success" onPress={closeHandler}>
                  Save & Exit
                </Button>
              </Modal.Footer>
            </Modal>
            </div>}
          </div>
        </div>
      </div>
    </>
  );
}

export default Menu;