import React, { useState } from 'react';
import { FcGoogle } from 'react-icons/fc'
import { BsTwitter } from 'react-icons/bs'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth, getData } from '../../firebase'
import { signInWithPopup, GoogleAuthProvider, TwitterAuthProvider } from 'firebase/auth';
import { useNavigate } from 'react-router-dom'
import './sign-in.css'
import { createCookie, getCookie } from '../../cookie';
import { getValue } from 'tsparticles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const SignIn = (props, onError, onSuccess, onWarning, onInfo) => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const navigate = useNavigate();
    const login = () => {
        signInWithEmailAndPassword(auth, email, password)
            .then(() => {
                createCookie('user', email, 7);
                createCookie('photoURL', "https://api.readyplayer.me/v1/avatars/643dc9e884ef6d6b93d81e07.png?cacheControl=true&scene=fullbody-portrait-v1&blendShapes[Wolf3D_Head][mouthSmile]=0.8&blendShapes[Wolf3D_Head][browInnerUp]=0.2", 7)
                createCookie('displayName', email, 7)
                props.closeLogin(false)
                console.log(getData('models', 'email', '==', email))
                getData('models', 'email', '==', email).then(res => {
                    if (!res)
                        navigate('/avatar')
                    else {
                        createCookie('gender', res[0].gender, 7)
                        createCookie('modelPath', res[0].file, 7)
                    }
                })
                props.onSuccess('Successfully logged in as '+ email);
            })
            .catch(err => props.onError(err.message))
    }

    const loginGuest = () => {
        createCookie('user', 'guest', 1);
        createCookie('name', "Guest", 1);
        createCookie('photoURL', "https://api.readyplayer.me/v1/avatars/643dc9e884ef6d6b93d81e07.png?cacheControl=true&scene=fullbody-portrait-v1&blendShapes[Wolf3D_Head][mouthSmile]=0.8&blendShapes[Wolf3D_Head][browInnerUp]=0.2", 7)
        props.closeLogin(false)
        props.onSuccess('Successfully logged in as a Guest');

        if (!getCookie('modelPath'))
            navigate('/avatar')
    }

    const register = () => {
        props.goRegister();
    }

    const loginGoogle = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                console.log(JSON.stringify(result.user))
                // ...
                console.log("COOKIE USER", result.user.email)
                createCookie('user', result.user.email, 7);
                
                console.log("COOKIE PHOTO", result.user.photoURL)
                createCookie('photoURL', result.user.photoURL, 7)

                console.log("COOKIE DISPLAY NAME", result.user.displayName)
                createCookie('displayName', result.user.displayName, 7)
                
                props.closeLogin(false)
                getData('models', 'email', '==', result.user.email).then(res => {
                    console.log(res);
                    if (!res)
                        navigate('/avatar')
                    else {
                        createCookie('gender', res[0].gender, 7)
                        createCookie('modelPath', res[0].file, 7)
                    }
                })
                props.onSuccess('Successfully logged in as '+ result.user.email);
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
                console.log(error.message)
                props.onError(error.message)
            });
    }

    const resetPassword = () => {

    }

    const loginTwitter = () => {
        const provider = new TwitterAuthProvider();
        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = TwitterAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                console.log("Twitter Result: ", result)
                console.log("COOKIE USER", result.user.email)
                createCookie('user', result.user.email, 7);
                
                console.log("COOKIE PHOTO", result.user.photoURL)
                createCookie('photoURL', result.user.photoURL, 7)

                console.log("COOKIE DISPLAY NAME", result.user.displayName)
                createCookie('displayName', result.user.displayName, 7)
                // ...
                // createCookie('user', result.user.email, 1);
                props.closeLogin(false)
                getData('models', 'email', '==', result.user.email).then(res => {
                    if (!res)
                        navigate('/avatar')
                    else {
                        createCookie('gender', res[0].gender, 7)
                        createCookie('modelPath', res[0].file, 7)
                    }
                })
                props.onSuccess('Successfully logged in as '+ result.user.email);
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
                console.log(error.message)
                props.onError(error.message)

            });
    }
    return (
        <div className='sign-in'>
       
            <div className='login-modal'>
                <div className='sign-header'>
                    <p className='p-register' onClick={() => { loginGuest() }} style={{ color: 'white', fontFamily: 'Dooms', textAlign: 'right', padding: '35px 25px', margin: '0px', float: 'right', display: 'inline-block', fontSize: '14px' }}>..Or continue as a Guest</p>
                </div>
                <div className='sign-body'>
                    <p style={{ color: 'white', fontSize: '36px', margin: '0px' }}>Login</p>
                    <div>
                        <p style={{ color: 'white', marginBottom: '5px' }}>Email</p>
                        <input
                            type='email'
                            placeholder='username@gmail.com'
                            onChange={(e) => { setEmail(e.target.value) }}
                            style={{
                                background: 'transparent',
                                marginBottom: '10px',
                                height: '50px',
                                borderRadius: '10px',
                                backgroundColor: '#23283D',
                                border: '1px solid grey',
                                color: 'white',
                                width: '100%',
                                outline: 'none',
                                padding: '10px'
                            }} />
                    </div>
                    <div>
                        <p style={{ color: 'white', marginBottom: '5px' }}>Password</p>
                        <input
                            type='password'
                            placeholder='Password'
                            onChange={(e) => { setPassword(e.target.value) }}
                            style={{
                                background: 'transparent',
                                marginBottom: '10px',
                                height: '50px',
                                borderRadius: '10px',
                                backgroundColor: '#23283D',
                                border: '1px solid grey',
                                color: 'white',
                                width: '100%',
                                outline: 'none',
                                padding: '10px'
                            }} />
                        <small className='p-register' onClick={() => { resetPassword() }} style={{ color: 'white' }}>Forgot password?</small>
                    </div>
                    <div style={{ margin: '30px 0px' }}>
                        <button onClick={() => { login() }} style={{ width: '100%', height: '50px', cursor: 'pointer', borderRadius: '10px', border: 'none', color: 'white', backgroundColor: '#003465' }}>Sign in</button>
                    </div>
                    <p style={{ width: '100%', color: 'white', textAlign: 'center', fontSize: '14px' }}>Or continue with</p>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <button onClick={() => { loginGoogle() }} style={{ width: '120px', cursor: 'pointer', fontSize: '24px', paddingTop: '10px', height: '50px', margin: '5px', backgroundColor: 'white', border: 'none', borderRadius: '10px' }} ><FcGoogle /></button>
                        <button onClick={() => { loginTwitter() }} style={{ width: '120px', fontSize: '24px', paddingTop: '10px', cursor: 'pointer', color: '#1c9ceb', height: '50px', margin: '5px', backgroundColor: 'white', border: 'none', borderRadius: '10px' }} ><BsTwitter /></button>
                    </div>
                    <p style={{ textAlign: 'center', fontSize: '14px', marginTop: '30px', color: 'white', width: '100%' }}>Don't have an account yet?  <strong className='span-register' onClick={() => { register() }} style={{ fontSize: '600' }}> Register for free</strong></p>
                    <p style={{ textAlign: 'center', fontSize: '12px', marginTop: '45px', color: 'white', width: '100%' }}>By proceeding you agree to our <a href="https://0xm0.com/pp/" > Privacy Policy</a> and <a href="https://0xm0.com/tos/" > Terms of Service</a> </p>
                </div>
                <div className='sign-footer'></div>
            </div>
        </div>
    );
}

export default SignIn;