const initialState = {
    isStateLoading: false,
    isModeFirstPerson: true
}


const GameReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_STATE_LOADING':
            return {
                ...state,
                isStateLoading: action.payload
            }
        case 'GET_MODE_FIRSTPERSON':
            return {
                ...state,
                isModeFirstPerson: action.payload
            }
        default:
            return state;
    }
}

export default GameReducer;