import { apiLink } from "./envHandler";
import "./App.css";
import "./youtube.scss";
import React, { Suspense, useEffect, useState, useRef } from "react";
import Bounce from "react-reveal/Bounce";
import { Canvas, useThree } from "@react-three/fiber";
import Scene from "./Body";
import * as THREE from "three";
// import Sky from "./Setup/Sky";
import Lights from "./Setup/Lights";
// import Particles from "react-particles-js";
// import { Stats } from "./Stats";
import { Stats } from "@react-three/drei";
import {
  Stars,
  Loader,
  OrbitControls,
  FirstPersonControls,
  Plane,
  PointerLockControls,
} from "@react-three/drei";
import { AmbientLight } from "three";
import Soldier from "./Character";
import { Debug, Physics, useBox, useSphere } from "@react-three/cannon";

import { useControls, button, buttonGroup, folder } from "leva";
import { useStore } from "./store";
import { useToggle } from "./useToggle";
import { useGLTF } from "@react-three/drei";
import { GridHelper } from "three";

// Creates a crate that catches the spheres
const axios = require("axios").default;
// Spheres falling down

function useForceUpdate() {
  const [value, setValue] = useState(0);
  return () => setValue((value) => value + 1);
}

const DebugPhysics = (props) => {
  const debug = useStore((state) => state.debug);
  const color = useStore((state) => state.color);
  console.log(color);

  return debug ? <Debug color={color}>{props.children}</Debug> : props.children;
};
const App = () => {
  const [colliders, setColliders] = useState(null)
  const [objectGroup, setGroup] = useState(null);
  const [isStart, setStart] = useState(false)
  const ToggledDebug = useToggle(Debug, "debug");
  useEffect(() => { console.log(colliders) }, [colliders]);
  const isBuild = useStore((state) => state.build)
  const [maxAngle, setAngle] = useState(null);
  const [minDistance, setMin] = useState(0);
  const [maxDistance, setMax] = useState(4);
  const cam = useRef();
  useEffect(() => {
    console.log(isBuild)
    if (isBuild)
      setAngle(Math.PI)
    else
      setAngle(Math.PI / 1.5)
  }, [isBuild])
  const childRef = useRef();
  const characterRef = useRef();
  const save = () => {
    childRef.current.saveTransform();
  }

  const getColliders = () => {
    childRef.current.getColliders();
  }

  const teleport = () => {
    characterRef.current.teleport();
  }

  const FirstPersonView = (mode) => {
    if (characterRef.current) {
      console.log("mode", mode);
      characterRef.current.toggleView(mode);
    }
  }
  const changeScenes = useStore((state) => state.changeScenes);
  // let listScene = null;
  const sendPostRequest = async (scene) => {
    try {
      const data = { body: scene }
      const resp = await axios.post(apiLink + "/getScenes", data);
      console.log(resp.data.Scenes)
      changeScenes(resp.data.Scenes)
      // listScene = resp.data.Scenes;
      return resp.data.Scenes;
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };
  useEffect(() => {
    sendPostRequest()
  }, [])


  const scenes = useStore((state) => state.scenes);
  return (
    <>
      <Canvas
        style={{
          height: "100vh",
          width: "100vw",
          backgroundColor: "black",
        }}
        camera={{ position: [0, 10, -5], fov: 40 }}
        id="canvas"
        shadowMap
        frameloop="demand"
        mode="concurrent"
        shadows
        raycaster={{
          computeOffsets: ({ clientX, clientY }) => ({
            offsetX: clientX,
            offsetY: clientY
          })
        }}
      >
        {/* <fog attach="fog" args={["#0d1a26", 60, 100]} /> */}
        {/* <ambientLight intensity={1.25} /> */}
        <ambientLight intensity={0.8}  />
        <directionalLight intensity={1.5}  position={[5, 10, 5]}/>

        <Suspense fallback={null}>
          <Physics
            gravity={[0, -50, 0]}
            defaultContactMaterial={{ restitution: 0.5 }}
          >
            <DebugPhysics>
              <Scene ref={childRef} isStart={isStart} setGroup={setGroup} setColliders={setColliders} map={useStore((state) => state.map)} isDebug={useStore((state) => state.debug)} />
              <Soldier ref={characterRef} camera={cam} setMin={setMin} setMax={setMax} setStart={setStart} pose={0} model={useStore((state) => state.character)} isBuild={useStore((state) => state.build)} map={useStore((state) => state.map)} colliders={colliders} />
            </DebugPhysics>
          </Physics>
        </Suspense>
        <Stars
          radius={160}
          depth={50}
          count={5000}
          factor={4}
          saturation={0}
          fade
        />
        {/* <Lights /> */}
        <OrbitControls
          ref={cam}
          makeDefault
          enableDamping
          minDistance={minDistance}
          maxDistance={maxDistance}
          maxPolarAngle={maxAngle}
        />
        {/* <PointerLockControls /> */}
        <Stats />
      </Canvas>
      {scenes && <Editor scenes={scenes} toggleView={FirstPersonView} setMin={setMin} setMax={setMax} setStart={setStart} getColliders={getColliders} teleport={teleport} save={save} object={objectGroup} />}
      <Loader
        containerStyles={{
          background: "#222",
        }} // Flex layout styles
        innerStyles={{
          backgroundColor: "#3800AE",
          width: "50vw",
          height: "2rem",
        }} // Inner container styles
        barStyles={{
          backgroundColor: "#5708FF",
          height: "2rem",
        }} // Loading-bar styles
        dataInterpolation={(p) => `Loading ${Math.round(p)}% .. Not stuck I promise!`}
        // dataInterpolation={(p) => `I'm not stuck - this can take a while`}
        initialState={(active) => active}
        dataStyles={{
          color: "#fff",
          fontSize: "25px",
          fontFamily: "poppins",
          fontWeight: "400",
          display: "none",
          // textTransform: "uppercase",
        }}
      />
    </>
  );
};

export default App;

function Editor(props) {
  const debugPhysics = useStore((state) => state.debug);
  const isBuild = useStore((state) => state.build);
  const changeState = useStore((state) => state.changeDebug);
  const changeCharacter = useStore((state) => state.changeCharacter);
  const changeMap = useStore((state) => state.changeMap);
  const changeImport = useStore((state) => state.changeImport);
  const changeSync = useStore((state) => state.changeSync);
  const changePosition = useStore((state) => state.changePosition);
  const changeRotation = useStore((state) => state.changeRotation);
  const changeScale = useStore((state) => state.changeScale);
  const position = useStore((state) => state.position);
  const rotation = useStore((state) => state.rotation);
  const scale = useStore((state) => state.scale);
  const scenes = useStore((state) => state.scenes);
  const target = useStore((state) => state.target);
  const forceUpdate = useForceUpdate();
  console.log(scenes)

  const save = () => {
    props.save();
  }

  const teleport = () => {
    props.teleport();
  }

  const generate = () => {
    props.getColliders();
  }

  useEffect(() => {
    console.log("update", props.scenes)
    forceUpdate();
  }, [props.scenes])

  const [{ debug, FirstPersonView, color, runVelocity, build, Position, Rotation, Scale }, set] = useControls(() => ({
    debug: debugPhysics,
    FirstPersonView: true,
    build: isBuild,
    "I'm Stuck!": button(() => generate()),
    // "Back to Spawn ": button(() => spawnpoint()),
    color: {
      value: "#ffffffff",
      render: (get) => get("debug"),
    },

    Build: folder(
      {
        Object: buttonGroup({
          'TP': () => { teleport(); },
          'GEN': () => { generate(); },
        }),
        Position: {
          x: position.x,
          y: position.y,
          z: position.z,
          onChange: (value, path, { initial, fromPanel }) => {
            changePosition(value.x, value.y, value.z);
          }
        },
        Rotation: {
          x: rotation.x,
          y: rotation.y,
          z: rotation.z,
          onChange: (value, path, { initial, fromPanel }) => {
            changeRotation(value.x, value.y, value.z);
          }
        },
        Scale: {
          x: scale.x,
          y: scale.y,
          z: scale.z,
          onChange: (value, path, { initial, fromPanel }) => {
            changeScale(value.x, value.y, value.z);
          }
        },
        "Save": button(() => {
          save()
        }),
      },
      { render: (get) => get('build') }
    ),
    runVelocity: 3,
  }));

  set({ Position: position })
  set({ Rotation: rotation })
  set({ Scale: scale })

  const character = useControls({
    Character: {
      value: "Pirate",
      options: ["Soldier", "Pirate"]
    },
  })

  const map = useControls({
    Map: {
      options: props.scenes,
    }
  })

  useEffect(() => {
    console.log("toggled", FirstPersonView)
    if (FirstPersonView) {
      props.setMax(0.2)
      props.setMin(0)
      props.toggleView(FirstPersonView)
    }
    else {
      props.setMax(5)
      props.setMin(4)
      props.toggleView(FirstPersonView)
    }
  }, [FirstPersonView])

  useEffect(() => {
    console.log(target)
    if (target !== null) {
      target.rotation.z = rotation.z
      target.rotation.y = rotation.y
      target.rotation.x = rotation.x

    }
  }, [rotation])

  useEffect(() => {
    if (target !== null) {
      target.scale.z = scale.z
      target.scale.y = scale.y
      target.scale.x = scale.x
    }
  }, [scale]);

  useEffect(() => {
    if (target !== null) {
      target.position.z = position.z
      target.position.y = position.y
      target.position.x = position.x
    }
  }, [position]);

  useEffect(() => {
    changeState(debug, color, runVelocity, build);
  }, [debug, color, runVelocity, build]);

  useEffect(() => {
    changeCharacter(character.Character);
  }, [character]);

  useEffect(() => {
    props.setStart(false)
    changeMap(map.Map);
  }, [map]);


  // set({ Position: position})
  return null;
}
